import reqeusts from "../../request";

export const updateUser = async ({ payload }) => {
  const data = await reqeusts.orfeo.patch(
    { payload } // baseURL, etc...
  );
  return data;
};

//고객센터 > 공지 > 공지 수정
export const updateAnnouncements = async ({ ...req }) => {
  const { ...payload } = req;
  const data = await reqeusts.orfeo.patch(`announcements/`, payload);
  return data;
};

//고객센터 > 이벤트 > 이벤트 수정
export const updateEvents = async ({ ...req }) => {
  const { ...payload } = req;
  const data = await reqeusts.orfeo.patch(`events/`, payload);
  return data;
};

//고객센터 > FAQ > FAQ 수정
export const updateFaq = async ({ ...req }) => {
  const { ...payload } = req;
  const data = await reqeusts.orfeo.patch(`faq/`, payload);
  return data;
};

//공연관리 > 공연 > 공연 수정
export const updateProducts = async ({ ...req }) => {
  const { ...payload } = req;
  const data = await reqeusts.orfeo.patch(`products/`, payload);
  return data;
};
