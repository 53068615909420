import reqeusts from "../../request";

//고객센터 > 공지 > 공지 추가
export const createAnnouncements = async (payload) => {
  const data = await reqeusts.orfeo.post("announcements", payload);
  return data;
};

//고객센터 > 이벤트 > 이벤트 추가
export const createEvents = async (payload) => {
  const data = await reqeusts.orfeo.post("events", payload);
  return data;
};

//고객센터 > FAQ > FAQ 추가
export const createFaq = async (payload) => {
  const data = await reqeusts.orfeo.post("faq", payload);
  return data;
};

//공연관리 > 공연 > 공연 추가
export const createProducts = async (payload) => {
  const data = await reqeusts.orfeo.post("products", payload);
  return data;
};
