import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createProducts } from "../../apis/orfeo/POST";
import { onErrorBind } from "../common";
import { getProducts } from "../../apis/orfeo/GET";
import { updateProducts } from "../../apis/orfeo/UPDATE";
import { deleteProducts } from "../../apis/orfeo/DELETE";

//공연
export function useCreateProducts() {
  const queryClient = useQueryClient();

  return useMutation((payload) => createProducts(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries(["get", "products/"]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useGetProducts() {
  return useQuery({
    queryKey: ["get", `products/`],
    queryFn: async () => {
      const { data } = await getProducts();
      return data;
    },
    onError: (e) => {
      console.log("e", e);
    },
  });
}

export function useUpdateProducts() {
  const queryClient = useQueryClient();

  return useMutation((req) => updateProducts(req), {
    onSuccess: (data, { id }) => {
      queryClient.invalidateQueries(["get", "products/"]);
      queryClient.invalidateQueries(["get", `products/${id}`]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useDeleteProducts() {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteProducts(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["get", "products/"]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}
