import { Typography } from "@mui/material";
import VerticalTabs from "../../../pages/ctr/VerticalTabs";

export default function UserManage() {
  return (
    <div className="wrap">
      <Typography
        variant="h5"
        style={{
          marginBottom: 8,
          fontWeight: 600,
        }}
      >
        사용자 관리
      </Typography>
      <VerticalTabs idx={"orfeo_userManage"} />
    </div>
  );
}
