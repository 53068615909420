import { Button, Paper, TextField } from "@mui/material";
import TinyEditor from "../../../../../utils/TinyEditor";
import {
  useLeavingGuardStore,
  useModalStore,
} from "../../../../../store/creates";
import { useRef, useCallback } from "react";
import { Field, Form, Formik } from "formik";
import Schema from "../../../../../utils/validate/Schema";
import { useCreateFaq } from "../../../../../query/queries/orfeo/customerService";
import MenuItem from "@mui/material/MenuItem";

const fields = [
  {
    name: "title",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
  },
  {
    name: "writor",
    type: "text",
    placeholder: "작성자를 입력해 주세요.",
  },
  {
    name: "category",
    type: null,
    select: true,
    options: ["nft", "ticketing", "event"],
  },
];

export default function PostFaq() {
  const { openModal } = useModalStore();
  const { mutateAsync } = useCreateFaq();
  const editorRef = useRef(null);

  const { setIsGuard } = useLeavingGuardStore();

  const addFaq = useCallback((values) => {
    const payload = { ...values };
    mutateAsync(payload);
  }, []);

  return (
    <div id="postFaq">
      <Formik
        enableReinitialize={true}
        initialValues={{
          title: "",
          content: "",
          writor: "",
          category: "",
        }}
        validationSchema={() => Schema.faq()}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          openModal(`FAQ를 추가 하시겠습니까?`, () => addFaq(values));
        }}
      >
        {({ errors, touched }) => (
          <Form onChange={() => setIsGuard(true)}>
            <Paper sx={{ p: 1 }}>
              {fields.map((f) => (
                <div className="rel" key={f.name}>
                  <Field
                    name={f.name}
                    type={f.type}
                    className="formik-input"
                    id={
                      errors[f.name] && touched[f.name] && "error-input-border"
                    }
                  >
                    {({ field, meta }) => (
                      <>
                        <TextField
                          {...field}
                          select={f.select}
                          sx={{ mb: 1, minWidth: 150 }}
                          label={f.name.toUpperCase()}
                          placeholder={f.placeholder}
                          error={meta.error && meta.touched}
                          helperText={meta.error && meta.touched && meta.error}
                        >
                          {f.options?.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </>
                    )}
                  </Field>
                </div>
              ))}
              <TinyEditor name="content" editorRef={editorRef} />
            </Paper>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 1 }}
              type="submit"
            >
              FAQ 등록
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
