import { getRefreshToken } from "../../../service/token";
import reqeusts from "../../request";

//상세 조회
export const getDetailsById = async (pageType, endpoint, id) => {
  const data = await reqeusts[pageType].get(`${endpoint}/${id}`);
  return data;
};

//토큰 재발급
export const reissuanceToken = async (pageType) => {
  const data = await reqeusts[pageType].get(`auth/reissueToken`, {
    headers: {
      Authorization: `Bearer ${getRefreshToken()}`,
    },
  });
  return data;
};
