import { Typography } from "@mui/material";
import VerticalTabs from "../../../pages/ctr/VerticalTabs";

export default function GoodsManage() {
  return (
    <div className="wrap">
      <Typography
        variant="h5"
        style={{
          marginBottom: 8,
          fontWeight: 600,
        }}
      >
        상품 관리
      </Typography>
      <VerticalTabs idx={"olea_goodsManage"} />
    </div>
  );
}
