import axios from "axios";
import { getAccessToken } from "./token";

const USER_URI = `${process.env.REACT_APP_API_URL}/api`;

export default class UserService {
  static async getUserData() {
    const { data } = await axios({
      method: "GET",
      url: `${USER_URI}/my`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }
  static async logout() {
    const { data } = await axios({
      method: "GET",
      url: `${USER_URI}/auth/logout`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }
  static async authCheck(redirectUrl) {
    const { data } = await axios({
      method: "GET",
      url: `${USER_URI}/auth/nice/main?redirectUrl=${redirectUrl}`,
      // params: { redirectUrl },
      withCredentials: true,
    })
      .then((res) => {
        let encodeData = res.data.data.sEncData;
        const windowOpen = window.open(
          "",
          "popupChk",
          "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
        );

        if (!windowOpen) {
          alert("팝업이 차단되었습니다.");
        } else {
          windowOpen.focus();
        }

        document.form_chk.action =
          "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
        document.form_chk.EncodeData.value = encodeData;
        document.form_chk.target = "popupChk";
        document.form_chk.submit();
        setTimeout(() => {
          document.form_chk.EncodeData.value = "";
        }, 0);
      })
      .catch((error) => {
        const message = error.response.data.message;
        alert(message);
      });

    console.log("NICE response data : ", data);
    return data;
  }
}
