import { Field, Form, Formik, FormikHelpers } from "formik";
import { Fragment, useEffect, useRef, useState } from "react";
import StyledErrorMessage from "../../../../../utils/StyledErrorMessage";
import Schema from "../../../../../utils/validate/Schema";
import "./SeatAdd.scss";
import { Stage, Layer, Rect, Text } from "react-konva";
import randomString from "../../../../../utils/randomString";
import Button from "@mui/material/Button";

interface Cell {
  x: number;
  y: number;
  filled: number;
}
interface GridProps {
  x: number;
  y: number;
  state: {
    sections: Section[];
    setSections: (newState: Section[]) => void;
    name: string;
  };
}

interface Section {
  name: string;
  grid: Cell[];
  size: { x: number; y: number };
}

function seatColor(type: number, hue = false) {
  if (hue) return "#212121";
  switch (type) {
    case 0:
      return "#D9D9D9";
    case 1:
      return "#8FC9C7";
    case 2:
      return "#0097a7";
    case 3:
      return "#02736F";
    default:
      return "#fff";
  }
}

function Grid({ x, y, state }: GridProps) {
  const { sections, setSections, name } = state;
  const [grid, setGrid] = useState<Cell[]>([]);
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const requestId = useRef<any>();

  useEffect(() => {
    if (grid.length === 0) return;
    const mapping = sections.map((section) => {
      if (section.name === name) return { ...section, grid: grid };
      return section;
    });
    setSections(mapping);
  }, [grid]);

  useEffect(() => {
    setGrid(createGrid(x, y));
  }, [x, y]);

  function handleMouseDown(index: number, evt: MouseEvent) {
    const newGrid = [...grid];
    if (evt.shiftKey) {
      newGrid[index].filled = 0;
    } else if (evt.ctrlKey) {
      newGrid[index].filled = 2;
    } else if (evt.altKey) {
      newGrid[index].filled = 3;
    } else {
      newGrid[index].filled = 1;
    }
    setGrid(newGrid);
    setIsDrawing(true);
  }
  function handleMouseUp() {
    setIsDrawing(false);
  }
  function handleMouseMove(index: number, evt: MouseEvent) {
    if (!isDrawing) return;
    // requestAnimationFrame을 사용하여 드로잉을 최적화
    requestId.current = window.requestAnimationFrame(() => {
      const newGrid = [...grid];
      if (evt.shiftKey) {
        newGrid[index].filled = 0;
      } else if (evt.ctrlKey) {
        newGrid[index].filled = 2;
      } else if (evt.altKey) {
        newGrid[index].filled = 3;
      } else {
        newGrid[index].filled = 1;
      }

      setGrid(newGrid);
    });
  }

  // 컴포넌트 언마운트 시 requestAnimationFrame 취소
  useEffect(() => {
    return () => {
      if (requestId.current) {
        window.cancelAnimationFrame(requestId.current);
      }
    };
  }, []);

  function createGrid(cols: number, rows: number): any {
    const newGrid: Array<{ x: number; y: number; filled: number }> = [];

    const mapLength = cols * rows;
    let x = 0;
    let y = 0;

    for (let index = 0; index < mapLength; index++) {
      if (x > cols - 1) {
        x = 0;
        y = y + 1;
      }
      newGrid[index] = { x: x, y: y, filled: 0 };
      x = x + 1;
    }

    return newGrid;
  }

  return (
    <div className="grid-container">
      <Stage width={30 * x} height={30 * y}>
        <Layer>
          {grid?.map((cell, cellIndex) => (
            <Fragment key={cellIndex}>
              <Rect
                stroke={"black"}
                strokeWidth={0.1}
                x={cell.x * 30}
                y={cell.y * 30}
                width={30}
                height={30}
                fill={seatColor(cell.filled)}
                onMouseDown={(e) => handleMouseDown(cellIndex, e.evt)}
                onMouseUp={handleMouseUp}
                onMouseMove={(e) => handleMouseMove(cellIndex, e.evt)}
              />
              {cell.x === 0 && (
                <Text
                  x={4}
                  y={cell.y * 30 + 4}
                  text={cell.y.toString()}
                  fill={seatColor(cell.filled, true)}
                  fontSize={11}
                />
              )}
              {cell.y === 0 && (
                <Text
                  x={cell.x * 30 + 4}
                  y={4}
                  text={cell.x.toString()}
                  fill={seatColor(cell.filled, true)}
                  fontSize={11}
                />
              )}

              {cell.x > 0 &&
                cell.y > 0 &&
                cell.x % 5 === 0 &&
                cell.y % 5 === 0 && (
                  <Text
                    x={cell.x * 30 + 4}
                    y={cell.y * 30 + 4}
                    text={cellIndex.toString()}
                    fill={seatColor(cell.filled)}
                    fontSize={11}
                  />
                )}
            </Fragment>
          ))}
        </Layer>
      </Stage>
    </div>
  );
}

export default function SeatAdd() {
  const [sections, setSections] = useState<Section[]>([]);

  const handleSubmit = (
    values: any,
    actions: FormikHelpers<any>,
    i: number
  ) => {
    actions.setSubmitting(false);
    const copy = sections.slice();
    copy[i] = {
      ...copy[i],
      name: values.name,
      size: { x: values.x, y: values.y },
    };
    console.log(copy);

    setSections(copy);
  };

  return (
    <div id="SeatAdd">
      <Button
        variant="contained"
        sx={{ mb: 1 }}
        onClick={(e) => {
          const newSection: Section = {
            name: randomString(),
            grid: [],
            size: { x: 20, y: 5 },
          };
          setSections([...sections, newSection]);
        }}
      >
        NEW SECTION
      </Button>
      <Stage width={320} height={70}>
        <Layer>
          {[
            "판매안함\n(shift+click)",
            "예매 중\n(click)",
            "응모 중\n(ctrl+click)",
            "판매 완료\n(alt+click)",
          ].map((text, i) => (
            <Fragment key={text}>
              <Rect
                stroke={"black"}
                strokeWidth={0.1}
                x={80 * i}
                y={0}
                width={80}
                height={60}
                fill={seatColor(i)}
              />
              <Text
                x={80 * i + 5}
                y={25}
                text={text}
                fill={seatColor(i, true)}
                fontSize={13}
              />
            </Fragment>
          ))}
        </Layer>
      </Stage>
      {sections.map((data, i) => (
        <div className="section" key={data.name}>
          <Formik
            initialValues={{
              name: data.name,
              x: data.size.x,
              y: data.size.y,
            }}
            validationSchema={Schema.seat}
            onSubmit={(values, action) => handleSubmit(values, action, i)} // onSubmit 함수 지정
          >
            {({ errors, touched, values }) => (
              <Form>
                <Button
                  variant="contained"
                  sx={{ mb: 1 }}
                  color="error"
                  type="button"
                  onClick={(e) => {
                    const filter = sections.filter(
                      (data) => data.name !== values.name
                    );
                    setSections(filter);
                  }}
                >
                  DELETE SECTION
                </Button>
                <div className="rel">
                  <Field
                    name="name"
                    type="text"
                    placeholder="섹션명을 입력해 주세요."
                    className="formik-input"
                    id={errors.name && touched.name ? "error-input-border" : ""}
                  />
                  <StyledErrorMessage
                    name="name"
                    id={errors.name && touched.name ? "error-on" : ""}
                  />
                </div>
                <div className="flex-tween">
                  <div className="rel">
                    <Field
                      name="x"
                      type="number"
                      placeholder="x 값을 입력해 주세요."
                      className="formik-input"
                      id={errors.x && touched.x ? "error-input-border" : ""}
                    />
                    <StyledErrorMessage
                      name="x"
                      id={errors.x && touched.x ? "error-on" : ""}
                    />
                  </div>
                  <div className="rel">
                    <Field
                      name="y"
                      type="number"
                      placeholder="y 값을 입력해 주세요."
                      className="formik-input"
                      id={errors.y && touched.y ? "error-input-border" : ""}
                    />
                    <StyledErrorMessage
                      name="y"
                      id={errors.y && touched.y ? "error-on" : ""}
                    />
                  </div>
                  <Button variant="contained" color="success" type="submit">
                    SAVE
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <Grid
            x={data.size.x}
            y={data.size.y}
            state={{ sections, setSections, name: data.name }}
          />
        </div>
      ))}
      <Button
        variant="contained"
        type="button"
        onClick={(e) => {
          console.log(sections);
        }}
      >
        SAVE
      </Button>
    </div>
  );
}
