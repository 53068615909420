import { create } from "zustand";

//토큰 스토어
export const useTokenStore = create((set) => ({
  token: { accessToken: null, refreshToken: null },
  setToken: (token) => set({ token }),
  clearToken: () => set({ token: { accessToken: null, refreshToken: null } }),
}));

// 모달 스토어
export const useModalStore = create((set) => ({
  isModalOpen: false,
  message: "",
  onConfirm: () => {},
  openModal: (message, onConfirm) =>
    set({ isModalOpen: true, message, onConfirm }),
  closeModal: () =>
    set({ isModalOpen: false, message: "", onConfirm: () => {} }),
}));

// 데이터 Export 스토어
export const useExportStore = create((set) => ({
  exportData: {},
  setExport: (data) => set({ exportData: data }),
  clearExport: () => set({ exportData: {} }),
}));

// 셀렉트 옵션 스토어
export const useOptionsStore = create((set) => ({
  options: {},
  setOptions: (options) => set({ options }),
  clearOptions: () => set({ options: [] }),
}));

// 탭 or 라우트 리빙 가드 스토어
export const useLeavingGuardStore = create((set) => ({
  isGuard: false,
  setIsGuard: (isGuard) => set({ isGuard: isGuard }),
  clearIsGuard: () => set({ isGuard: false }),
}));

// 라우팅 추적 스토어
export const useRouteFollowerStore = create((set) => ({
  pageType: "",
  setFollower: (target) => set({ pageType: target }),
  clearFollower: () => set({ pageType: "" }),
}));
