import { useState } from "react";
import { useEffect } from "react";
import "./DevTool.scss";
import M_Default from "./M_Default.jsx";

export default function DevTool() {
  const [isPaletteOpen, setIspaletteOpen] = useState(false);

  useEffect(() => {}, []);

  return (
    <>
      <button
        className="btn_settingOpen fas fa-cog"
        onClick={() => {
          setIspaletteOpen(!isPaletteOpen);
        }}
        style={{ right: 30 }}
        type="button"
      >
        TEST
      </button>
      {isPaletteOpen && (
        <section className="palette">
          <M_Default />
          <button
            type="button"
            className="btn_settingClose fas fa-times-circle"
            tabIndex="-1"
            onClick={() => {
              setIspaletteOpen(!isPaletteOpen);
            }}
          ></button>
        </section>
      )}
    </>
  );
}
