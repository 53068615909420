import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getDetailsById } from "../apis/common/GET";
import { useRouteFollowerStore } from "../../store/creates";

export const useGetDetailInfo = (queryKey, id) => {
  const queryClient = useQueryClient();
  const { pageType } = useRouteFollowerStore();

  return useQuery({
    queryKey: ["get", `${queryKey}/${id}`],
    queryFn: async () => {
      const { data } = await getDetailsById(pageType, queryKey, id);

      return data;
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
};

export function onErrorBind(e) {
  console.log(e);
}
