import { useEffect, useMemo } from "react";
import { useGetClubNftUser } from "../../../../../../../query/queries/olea/users";
import MuiTable from "../../../../../../common/MuiTable";
import { useOptionsStore } from "../../../../../../../store/creates";

export function ClubRequest() {
  // GET
  const { data: clubNftUser, isSuccess } = useGetClubNftUser();
  const originData = useMemo(() => clubNftUser || [], [clubNftUser]);
  const { setOptions, clearOptions, options } = useOptionsStore();

  useEffect(() => {
    //MuiTable uniKey와 같은 키 이름으로 옵션 추가
    setOptions({
      ...options,
      clubNftsId: [
        {
          name: "승인",
          action: (id: number, data: unknown) => {
            console.log(id);
            console.log(data);
          },
        },
      ],
    });
    return () => {
      clearOptions();
    };
  }, []);
  return (
    <div id="ClaimManage">
      {isSuccess && originData.length > 0 && (
        <MuiTable
          rows={originData}
          uniKey="clubNftsId" //get data key
          headers={Object.keys(originData[0])}
          // queryKey="clubNfts" //api endpoint
        />
      )}
    </div>
  );
}
