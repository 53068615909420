import { Typography } from "@mui/material";
import VerticalTabs from "../../../pages/ctr/VerticalTabs";

export default function CustomerService() {
  return (
    <div id="customerService">
      <Typography
        variant="h5"
        style={{
          marginBottom: 8,
          fontWeight: 600,
        }}
      >
        고객센터
      </Typography>

      <VerticalTabs idx={"orfeo_customerService"} />
    </div>
  );
}
