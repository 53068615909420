import React, { useMemo } from "react";
import "./Signin.scss";
import { Field, Form, Formik } from "formik";
import Schema from "../../utils/validate/Schema";
import StyledErrorMessage from "../../utils/StyledErrorMessage";
import { useNiceModule } from "../hooks/useNiceModule";
import { useSignIn } from "../../query/queries/olea/users";

export default function Signin({ loading }) {
  const [openNiceModal, NiceForm] = useNiceModule();
  // POST, UPDATE, DELETE
  const { data: token, mutateAsync } = useSignIn();
  const originToken = useMemo(() => token || "signIn", [token]);

  return (
    <div className="signin-body">
      <NiceForm />
      {loading && <div className="loader">로딩 중...</div>}
      <div className="effect horizontal"></div>
      <div className="effect horizontal"></div>
      <div className="effect horizontal"></div>
      <div className="effect horizontal"></div>
      <div className="effect vertical"></div>
      <div className="effect vertical"></div>
      <div className="effect vertical"></div>
      <div className="effect vertical"></div>
      <div className="book">
        <div className="cover"></div>
        <h2>
          OLEA <span>ADMIN</span>
        </h2>
        <span>
          Design By <i>AEGISECU</i>
        </span>
      </div>
      <div className="signin-container">
        <div className="imgBx"></div>
        <div className="mainBx">
          <Formik
            enableReinitialize={true}
            initialValues={{
              number: "",
              password: "",
            }}
            validationSchema={Schema.signin}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              mutateAsync({ number: values.number, password: values.password });
            }}
          >
            {({ errors, touched, values, setValues }) => (
              <>
                <Form noValidate>
                  <h1>sign in</h1>
                  <div className="rel">
                    <Field
                      name="number"
                      type="string"
                      placeholder="연락처를 입력해 주세요."
                      className="formik-input"
                      id={
                        errors.number && touched.number && "error-input-border"
                      }
                    />
                    <StyledErrorMessage
                      name="number"
                      errors={errors}
                      touched={touched}
                      id={errors.number && touched.number && "error-on"}
                    />
                  </div>
                  <div className="rel">
                    <Field
                      name="password"
                      type="password"
                      placeholder="비밀번호를 입력해 주세요."
                      className="formik-input"
                      id={
                        errors.password &&
                        touched.password &&
                        "error-input-border"
                      }
                    />
                    <StyledErrorMessage
                      name="password"
                      errors={errors}
                      touched={touched}
                      id={errors.password && touched.password && "error-on"}
                    />
                  </div>

                  <div className="btn-group">
                    <button type="button" onClick={openNiceModal}>
                      NICE 로그인
                    </button>
                    <button type="submit">로그인</button>
                    <button type="button">회원가입</button>
                    <button type="button">아이디/비밀번호 찾기</button>
                  </div>
                </Form>
              </>
            )}
          </Formik>

          <div className="decoration">
            <div className="content">
              <h2>매뉴얼</h2>
              <p>계정이 없다면 회원가입을 눌러 계정을 생성해주세요.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
