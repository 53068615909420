import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import randomString from "../../utils/randomString";
import MuiTableSplitButton from "./MuiTableSplitButton";
import TablePagination from "@mui/material/TablePagination";
import useClasses from "../hooks/useClasses";
import { useGetDetailInfo } from "../../query/queries/common";
import MuiDetailField from "./MuiDetailField";

interface RowCollapseBasicProps {
  data: {
    id: number;
    row: any;
    uniKey: string;
    queryKey: string;
  };
}

interface RowCollapseDetailProps {
  data: {
    id: number;
    uniKey: string;
    queryKey: string;
  };
}

function RowCollapseBasic({ data }: RowCollapseBasicProps) {
  const { id, row, uniKey } = data;

  return (
    <Box sx={{ margin: 1 }}>
      <MuiTableSplitButton id={id} data={row} uniKey={uniKey} />
    </Box>
  );
}

function RowCollapseDetail({ data }: RowCollapseDetailProps) {
  const { id, uniKey, queryKey } = data;

  const { data: resData, isSuccess } = useGetDetailInfo(queryKey, id);
  const originData = React.useMemo(() => resData || [], [resData]);

  return (
    <Box sx={{ margin: 1, position: "relative" }}>
      {isSuccess && <MuiDetailField row={originData} />}
    </Box>
  );
}

function Row(props: {
  row: any;
  id: number;
  uniKey: string;
  queryKey: string;
}) {
  const { row, id, uniKey, queryKey } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {Object.keys(row).map((key) => (
          <TableCell component="td" scope="row" key={key}>
            {JSON.stringify(row[key])}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {open && (
              <>
                <RowCollapseBasic data={{ row, id, uniKey, queryKey }} />
                {queryKey && (
                  //api endpoint가 있을 경우
                  <RowCollapseDetail data={{ id, uniKey, queryKey }} />
                )}
              </>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const styles = (theme: any) => ({
  root: {
    "& .MuiTablePagination-toolbar": {
      background: "#d1c4e9",
    },
  },
});

export default function MuiTable({
  headers = [""], //thead
  rows = [], //tbody
  uniKey = randomString(), //api data id
  queryKey = "", //api endpoint
}) {
  const classes: any = useClasses(styles);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {headers.map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <Row
                key={row[uniKey]}
                row={row}
                id={row[uniKey]}
                uniKey={uniKey}
                queryKey={queryKey}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.root}
      />
    </>
  );
}
