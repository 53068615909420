import reqeusts from "../../request";

//고객센터 > 공지 > 공지 조회
export const getAnnouncements = async (id = "") => {
  const data = await reqeusts.orfeo.get(`announcements/${id}`);
  return data;
};

//고객센터 > 이벤트 > 이벤트 조회
export const getEvents = async (id = "") => {
  const data = await reqeusts.orfeo.get(`events/${id}`);
  return data;
};
//고객센터 > FAQ > FAQ 조회
export const getFaq = async (id = "") => {
  const data = await reqeusts.orfeo.get(`faq/${id}`);
  return data;
};

//공연관리 > 공연 > 공연 조회
export const getProducts = async (id = "") => {
  const data = await reqeusts.orfeo.get(`products/${id}`);
  return data;
};
