import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useState } from "react";
import { Skeleton } from "@mui/material";
import clsx from "clsx";
import { collapseToggle } from "../../../utils/CollapseContainer.jsx";
import CollapseContainer from "../../../utils/CollapseContainer.jsx";
import Options from "../../../utils/Options";
import useClasses from "../../hooks/useClasses";
import SeatAdd from "../../menu/orfeo/seatManage/Seat/SeatAdd";
import ClaimManage from "../../menu/olea/userManage/market/ClaimManage";
import UserTypeChange from "../../menu/olea/userManage/market/userTypeChange/UserTypeChange.tsx";
import GetNotice from "../../menu/calliope/CustomerService/Notice/GetNotice.jsx";
import HorizontalTabs from "./HorizontalTabs.jsx";
import { useLeavingGuardStore, useModalStore } from "../../../store/creates.js";
import GetAnnouncements from "../../menu/orfeo/customerService/Announcements/GetAnnouncements.jsx";
import GetEvents from "../../menu/orfeo/customerService/Events/GetEvents.jsx";
import GetFaq from "../../menu/orfeo/customerService/Faq/GetFaq.jsx";
import PostProducts from "../../menu/orfeo/productsManage/Products/PostProducts.jsx";

const styles = (theme) => ({
  box: {
    overflow: "hidden",
    background: "#eee",
    borderRadius: 3,
    border: 0,
    minHeight: 48,
    padding: 8,
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .15)",
  },
  label: {
    fontFamily: "'Noto Sans KR', sans-serif",
    fontSize: 16,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: "100%", overflow: "auto" }}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function VerticalTabs(props) {
  const classes = useClasses(styles);
  const { idx, autoHeight } = props;
  const [value, setValue] = useState(0);
  const { openModal } = useModalStore();
  const { isGuard, setIsGuard } = useLeavingGuardStore();
  const handleChange = (event, newValue) => {
    if (isGuard) {
      openModal("입력한 데이터가 사라져도 괜찮습니까?", () => {
        setValue(newValue);
        setIsGuard(false);
      });
    } else {
      setValue(newValue);
    }
  };
  const [isCollapse, setIsCollapse] = useState([0, 1, 2, 3]);
  const [verticalDatas, setVerticalDatas] = useState({});

  useEffect(() => {
    //olea
    const olea_userManage = {
      ctrDeps: ["월렛", "마켓"],
      details: [
        [
          {
            label: "입고 요청 조회/승인",
            component: (
              <CollapseContainer
                content={
                  <Box sx={{ p: 1 }}>
                    <Box className={clsx(classes.box)}>
                      <Skeleton variant="rounded" height={60} />
                    </Box>
                  </Box>
                }
                isOpen={isCollapse.some((data) => data === 0) || false}
              />
            ),
          },
          {
            label: "입고 요청 등록",
            component: (
              <CollapseContainer
                content={
                  <Box sx={{ p: 1 }}>
                    <Box className={clsx(classes.box)}></Box>
                  </Box>
                }
                isOpen={isCollapse.some((data) => data === 1) || false}
              />
            ),
          },
          {
            label: "클레임 요청 조회/승인",
            component: (
              <CollapseContainer
                content={
                  <Box sx={{ p: 1 }}>
                    <Box className={clsx(classes.box)}></Box>
                  </Box>
                }
                isOpen={isCollapse.some((data) => data === 2) || false}
              />
            ),
          },
          {
            label: "클레임 요청 전송",
            component: (
              <CollapseContainer
                content={
                  <Box sx={{ p: 1 }}>
                    <Box className={clsx(classes.box)}></Box>
                  </Box>
                }
                isOpen={isCollapse.some((data) => data === 3) || false}
              />
            ),
          },
        ],
        [
          {
            label: "사용자 타입 변경",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <UserTypeChange />
                </Box>
              </Box>
            ),
          },
          {
            label: "경고 처리",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "블랙리스트 관리",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "클레임 관리",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <ClaimManage />
                </Box>
              </Box>
            ),
          },
        ],
      ],
    };
    const olea_marketManage = {
      ctrDeps: ["holder", "ticket", "club"],
      details: [
        [
          {
            label: "카테고리 범주 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "민팅 이벤트",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "민팅 이벤트",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "카테고리 범주 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
      ],
    };
    const olea_goodsManage = {
      ctrDeps: ["굿즈", "일반", "nft"],
      details: [
        [
          {
            label: "미정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "조회",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "제거",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "조회",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "제거",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
      ],
    };
    //orfeo
    const orfeo_seatManage = {
      ctrDeps: ["좌석"],
      details: [
        [
          {
            label: "조회",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <SeatAdd />
                </Box>
              </Box>
            ),
          },
          {
            label: "수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
      ],
    };
    const orfeo_userManage = {
      ctrDeps: ["유저"],
      details: [
        [
          {
            label: "조회",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
      ],
    };
    const orfeo_customerService = {
      ctrDeps: ["공지", "이벤트", "FAQ"],
      details: [
        [
          {
            label: "공지 조회 및 삭제",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <GetAnnouncements />
                </Box>
              </Box>
            ),
          },
          {
            label: "공지 등록 및 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <HorizontalTabs idx={"orfeo_customerService_announcements"} />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "이벤트 조회 및 삭제",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <GetEvents />
                </Box>
              </Box>
            ),
          },
          {
            label: "이벤트 등록 및 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <HorizontalTabs idx={"orfeo_customerService_events"} />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "FAQ 조회",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <GetFaq />
                </Box>
              </Box>
            ),
          },
          {
            label: "FAQ 등록 및 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <HorizontalTabs idx={"orfeo_customerService_faq"} />
                </Box>
              </Box>
            ),
          },
        ],
      ],
    };
    const orfeo_productsManage = {
      ctrDeps: ["공연"],
      details: [
        [
          {
            label: "조회",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <PostProducts />
                </Box>
              </Box>
            ),
          },
          {
            label: "수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
      ],
    };
    //calliope
    const calliope_customerService = {
      ctrDeps: ["공지", "테스트"],
      details: [
        [
          {
            label: "공지 조회 및 삭제",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <GetNotice />
                </Box>
              </Box>
            ),
          },
          {
            label: "공지 등록 및 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <HorizontalTabs idx={"calliope_customerService_notice"} />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "테스트",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
      ],
    };

    const menus = {
      olea_userManage,
      olea_marketManage,
      olea_goodsManage,
      orfeo_seatManage,
      orfeo_userManage,
      orfeo_productsManage,
      orfeo_customerService,
      calliope_customerService,
    };
    setVerticalDatas(menus);
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        height: autoHeight ? "auto" : "calc(100vh - 160px)",
        minHeight: 200,
      }}
    >
      {verticalDatas[idx] && (
        <>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            TabIndicatorProps={{ style: { background: "#636190" } }}
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            {verticalDatas[idx].ctrDeps.map((data, i) => (
              <Tab key={data} label={data} {...a11yProps(i)} />
            ))}
          </Tabs>
          {verticalDatas[idx].ctrDeps.map((data, i) => (
            <TabPanel key={data} value={value} index={i}>
              {verticalDatas[idx].details[i].map((detail, j) => (
                <div
                  key={detail.label + i}
                  className="menu"
                  style={{ marginBottom: 8 }}
                >
                  <Typography
                    variant="h7"
                    className={
                      `btn_toggle${
                        isCollapse.some((data) => data === j) ? " on" : ""
                      }` +
                      " " +
                      clsx(classes.label)
                    }
                    onClick={(e) => {
                      setIsCollapse(collapseToggle(isCollapse, j));
                    }}
                  >
                    {detail.label}
                  </Typography>
                  <CollapseContainer
                    content={detail.component}
                    isOpen={isCollapse.some((data) => data === j) || false}
                  />
                </div>
              ))}
            </TabPanel>
          ))}
        </>
      )}
    </Box>
  );
}

export default VerticalTabs;
