import UserService from "../../service/userService";

const NiceForm = () => {
  return (
    <form className="main__login-box" name="form_chk" method="post">
      <input type="hidden" name="m" value="checkplusService" />
      <input type="hidden" name="EncodeData" />
      <input type="hidden" id="data-mobileno" />
    </form>
  );
};

export const useNiceModule = () => {
  // const { pathname } = useSelector((state) => state.router.location);

  const pathname = window.location.pathname;

  const openNiceModal = async () => {
    try {
      let wl = window.location;
      let redirectUrl = `${wl.protocol}//${wl.host}${pathname}`;
      await UserService.authCheck(redirectUrl);
    } catch (err) {
      if (err.response) {
        const data = { ...err.response.data.error };
        alert(data.message);
      }
      return;
    }
  };

  return [openNiceModal, NiceForm];
};
