import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import clsx from "clsx";
import { useState } from "react";
import { useEffect } from "react";
import useClasses from "../../hooks/useClasses";
import CollapseContainer, {
  collapseToggle,
} from "../../../utils/CollapseContainer";
import { ClubAdd } from "../../menu/olea/userManage/market/userTypeChange/club/ClubAdd";
import { ClubRequest } from "../../menu/olea/userManage/market/userTypeChange/club/ClubRequest";
import PostNotice from "../../menu/calliope/CustomerService/Notice/PostNotice";
import UpdateNotice from "../../menu/calliope/CustomerService/Notice/UpdateNotice";
import { useLeavingGuardStore, useModalStore } from "../../../store/creates";
import PostAnnouncements from "../../menu/orfeo/customerService/Announcements/PostAnnouncements";
import UpdateAnnouncements from "../../menu/orfeo/customerService/Announcements/UpdateAnnouncements";
import PostEvents from "../../menu/orfeo/customerService/Events/PostEvents";
import UpdateEvents from "../../menu/orfeo/customerService/Events/UpdateEvents";
import PostFaq from "../../menu/orfeo/customerService/Faq/PostFaq";
import UpdateFaq from "../../menu/orfeo/customerService/Faq/UpdateFaq";

const styles = (theme) => ({
  box: {
    background: "#fff",
    borderRadius: 3,
    border: 0,
    minHeight: 48,
    padding: "8px",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .15)",
  },
  label: {
    fontFamily: "'Noto Sans KR', sans-serif",
    fontSize: 16,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function HorizontalTabs(props) {
  const classes = useClasses(styles);
  const [value, setValue] = useState(0);
  const { idx } = props;
  const [isCollapse, setIsCollapse] = useState([0, 1]);
  const { openModal } = useModalStore();
  const { isGuard, setIsGuard } = useLeavingGuardStore();
  const [horizontalDatas, setHorizontalDatas] = useState({});

  const handleChange = (event, newValue) => {
    if (isGuard) {
      openModal("입력한 데이터가 사라져도 괜찮습니까?", () => {
        setValue(newValue);
        setIsGuard(false);
      });
    } else {
      setValue(newValue);
    }
  };

  useEffect(() => {
    const olea_userManage_userTypeChange = {
      ctrDeps: ["클럽 등록", "스타터 등록"],
      details: [
        [
          {
            label: "club 등록 요청 조회/승인",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <ClubRequest />
                </Box>
              </Box>
            ),
          },
          {
            label: "club nft 등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <ClubAdd />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "스타터 등록 요청 조회/승인",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "스타터 nft 등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
      ],
    };
    const calliope_customerService_notice = {
      ctrDeps: ["등록", "수정"],
      details: [
        [
          {
            label: "공지 등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <PostNotice />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "공지 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <UpdateNotice />
                </Box>
              </Box>
            ),
          },
        ],
      ],
    };
    const orfeo_customerService_announcements = {
      ctrDeps: ["등록", "수정"],
      details: [
        [
          {
            label: "공지 등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <PostAnnouncements />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "공지 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <UpdateAnnouncements />
                </Box>
              </Box>
            ),
          },
        ],
      ],
    };
    const orfeo_customerService_events = {
      ctrDeps: ["등록", "수정"],
      details: [
        [
          {
            label: "이벤트 등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <PostEvents />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "이벤트 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <UpdateEvents />
                </Box>
              </Box>
            ),
          },
        ],
      ],
    };
    const orfeo_customerService_faq = {
      ctrDeps: ["등록", "수정"],
      details: [
        [
          {
            label: "FAQ 등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <PostFaq />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "FAQ 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <UpdateFaq />
                </Box>
              </Box>
            ),
          },
        ],
      ],
    };

    const menus = {
      olea_userManage_userTypeChange,
      calliope_customerService_notice,
      orfeo_customerService_announcements,
      orfeo_customerService_events,
      orfeo_customerService_faq,
    };
    //초기화
    setHorizontalDatas(menus);
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {horizontalDatas[idx] && (
        <>
          <Tabs
            TabIndicatorProps={{ style: { background: "#636190" } }}
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            {horizontalDatas[idx].ctrDeps.map((data, i) => (
              <Tab key={data} label={data} {...a11yProps(i)} />
            ))}
          </Tabs>
          {horizontalDatas[idx].ctrDeps.map((data, i) => (
            <TabPanel key={data} value={value} index={i}>
              {horizontalDatas[idx].details[i].map((detail, j) => (
                <div key={detail.label + i}>
                  <Typography
                    variant="h7"
                    className={
                      `btn_toggle${
                        isCollapse.some((data) => data === j) ? " on" : ""
                      }` +
                      " " +
                      clsx(classes.label)
                    }
                    onClick={(e) => {
                      setIsCollapse(collapseToggle(isCollapse, j));
                    }}
                  >
                    {detail.label}
                  </Typography>

                  <CollapseContainer
                    content={detail.component}
                    isOpen={isCollapse.some((data) => data === j) || false}
                  />
                </div>
              ))}
            </TabPanel>
          ))}
        </>
      )}
    </Box>
  );
}
export default HorizontalTabs;
