import React, { useEffect, useRef, useState } from "react";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import StoreIcon from "@mui/icons-material/Store";
import CategoryIcon from "@mui/icons-material/Category";
import MovieIcon from "@mui/icons-material/Movie";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import NotFound from "./NotFound";
import Home from "./Home";
import DevTool from "../tools/DevTool";
import Signin from "./Signin";
import oleaIcon from "../../imgs/OLEA/olea_favicon.ico";
import orfeoIcon from "../../imgs/ORFEO/ORFEO_cultures.png";
import calliopeIcon from "../../imgs/CALLIOPE/calliope_logo.png";
import { styled, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import SeatManage from "../menu/orfeo/seatManage/SeatManage";
import swaggerLogo from "../../imgs/Swagger-logo.png";
import ConfirmModal from "../../utils/ConfirmModal";
import OleaUserManage from "../menu/olea/userManage/UserManage";
import OrfoUserManage from "../menu/orfeo/userManage/UserManage";

import MarketManage from "../menu/olea/marketManage/MarketManage";
import GoodsManage from "../menu/olea/goodsManage/GoodsManage";
import TokenService from "../../service/token";
import {
  useLeavingGuardStore,
  useModalStore,
  useRouteFollowerStore,
  useTokenStore,
} from "../../store/creates";
import CalliopeCustomerService from "../menu/calliope/CustomerService/CustomerService";
import OrfeoCustomerService from "../menu/orfeo/customerService/CustomerService";
import ProductsManage from "../menu/orfeo/productsManage/ProductsManage";

const drawerWidth = 280;

const MuiMain = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),

    marginLeft: 0,
    overflow: "hidden",
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

let idx = 0;

const privateRouters = [
  {
    id: idx++,
    path: "/signin",
    Component: <Signin />,
  },
];

const oleaMenus = [
  {
    id: idx++,
    path: "/olea/users",
    text: "사용자 관리",
    Component: <OleaUserManage />,
    icon: <AssignmentIndIcon />,
  },
  {
    id: idx++,
    path: "/olea/market",
    text: "마켓 관리",
    Component: <MarketManage />,
    icon: <StoreIcon />,
  },
  {
    id: idx++,
    path: "/olea/goods",
    text: "상품 관리",
    Component: <GoodsManage />,
    icon: <CategoryIcon />,
  },
];

const orfeoMenus = [
  {
    id: idx++,
    path: "/orfeo/users",
    text: "사용자 관리",
    Component: <OrfoUserManage />,
    icon: <AssignmentIndIcon />,
  },
  {
    id: idx++,
    path: "/orfeo/products",
    text: "공연 관리",
    Component: <ProductsManage />,
    icon: <MovieIcon />,
  },
  {
    id: idx++,
    path: "/orfeo/seat",
    text: "좌석 관리",
    Component: <SeatManage />,
    icon: <AirlineSeatReclineNormalIcon />,
  },
  {
    id: idx++,
    path: "/orfeo/customerService",
    text: "고객센터",
    Component: <OrfeoCustomerService />,
    icon: <SupportAgentIcon />,
  },
];

const calliopeMenus = [
  {
    id: idx++,
    path: "/calliope/customerService",
    text: "고객센터",
    Component: <CalliopeCustomerService />,
    icon: <SupportAgentIcon />,
  },
];

const categories = [
  { name: "OLEA 관리", icon: oleaIcon, menus: oleaMenus },
  { name: "ORFEO 관리", icon: orfeoIcon, menus: orfeoMenus },
  { name: "CALLIOPE 관리", icon: calliopeIcon, menus: calliopeMenus },
];

export default function Main() {
  const { token, clearToken } = useTokenStore();
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true); //좌측 네비 토글 여부
  const [openSub, setOpenSub] = useState([true, true, true]); //메뉴 갯수 만큼 필요 (OLEA, ORFEO, CALLIOPE 관리)
  const { openModal } = useModalStore();
  const { isGuard, setIsGuard } = useLeavingGuardStore();
  const { setFollower } = useRouteFollowerStore();
  const { pathname } = useLocation();

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleSubDrawer = (idx) => {
    const copy = openSub.slice();
    copy[idx] = !copy[idx];
    setOpenSub(copy);
  };

  useEffect(() => {
    const regex = /\/(.*?)\//;
    const match = pathname.match(regex);

    if (match) {
      const extractedContent = match[1];
      setFollower(extractedContent);
    }
  }, [pathname]);

  return (
    <Box sx={{ display: "flex" }}>
      <ConfirmModal />
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <div className="flex-tween logo">
            <div className="rel">
              <Typography
                variant="h6"
                style={{ cursor: "pointer" }}
                noWrap
                onClick={() => {
                  navigate("/");
                }}
              >
                BLOCKCHAIN
              </Typography>
              <figure className="flex-center">
                <img
                  src={swaggerLogo}
                  className="ico30"
                  onClick={() => {
                    window.open(
                      "https://admin-api-dev.oleamarket.com/api-docs/",
                      "",
                      "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
                    );
                  }}
                />
                <figcaption className="ml8">OLEA</figcaption>
              </figure>
              <figure className="flex-center">
                <img
                  src={swaggerLogo}
                  className="ico30"
                  onClick={() => {
                    window.open(
                      "https://orfeo-back.oleamarket.com/api-docs/",
                      "",
                      "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
                    );
                  }}
                />
                <figcaption className="ml8">ORFEO</figcaption>
              </figure>
            </div>
            {token.accessToken ? (
              <Typography
                variant="button"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  TokenService.clear();
                  clearToken();
                  navigate("/");
                }}
              >
                로그아웃
              </Typography>
            ) : (
              <Typography
                variant="button"
                style={{
                  cursor: "pointer",
                }}
              >
                <Link to="/signin">로그인</Link>
              </Typography>
            )}

            {/* {getAccessToken()} */}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawer}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {token.accessToken &&
          categories.map((category, idx) => (
            <React.Fragment key={category.name}>
              <List>
                <ListItem button onClick={() => handleSubDrawer(idx)}>
                  <ListItemIcon>
                    <img
                      src={category.icon}
                      alt={category.name}
                      style={{ width: 40 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={category.name} />
                  {openSub[idx] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openSub[idx]} timeout="auto" unmountOnExit>
                  <List component="div">
                    {category.menus.map((data) => (
                      <Link
                        to={data.path}
                        key={data.id}
                        onClick={(e) => {
                          if (isGuard && data.path !== pathname) {
                            e.preventDefault();
                            openModal(
                              "입력한 데이터가 사라져도 괜찮습니까?",
                              () => {
                                setIsGuard(false);
                                navigate(data.path);
                              }
                            );
                          }
                        }}
                      >
                        <ListItem>
                          <ListItemIcon>{data.icon}</ListItemIcon>
                          <ListItemText primary={data.text} />
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </Collapse>
              </List>
              <Divider />
            </React.Fragment>
          ))}
      </Drawer>
      <MuiMain open={open}>
        <DrawerHeader />

        <Routes>
          {token.accessToken ? (
            <>
              {oleaMenus.map((data) => (
                <Route
                  key={data.id}
                  path={data.path}
                  element={data.Component}
                />
              ))}
              {orfeoMenus.map((data) => (
                <Route
                  key={data.id}
                  path={data.path}
                  element={data.Component}
                />
              ))}
              {calliopeMenus.map((data) => (
                <Route
                  key={data.id}
                  path={data.path}
                  element={data.Component}
                />
              ))}
            </>
          ) : (
            <>
              {privateRouters.map((data) => (
                <Route
                  key={data.id}
                  path={data.path}
                  element={data.Component}
                />
              ))}
            </>
          )}

          <Route path="/" element={<Home />} />
          <Route element={<NotFound />} />
        </Routes>
      </MuiMain>
      <DevTool />
    </Box>
  );
}
