import Collapse from "@kunukn/react-collapse";
import React from "react";

export default function CollapseContainer({ content, isOpen }) {
  return (
    <Collapse addState isOpen={isOpen}>
      {content}
    </Collapse>
  );
}

export function collapseToggle(isCollapse, i) {
  const some = isCollapse.some((data) => data === i);

  if (some) {
    const filter = isCollapse.filter((data) => data !== i);
    return filter;
  } else return [...isCollapse, i];
}
