import axios from "axios";

import TokenService from "../service/token";
import { reissuanceToken } from "./apis/common/GET";

function BindInterceptors(baseURL, pageType) {
  let isTokenRefreshing = false;
  let refreshSubscribers = [];

  const onTokenRefreshed = (accesstoken) => {
    refreshSubscribers.map((callback) => {
      return callback(accesstoken);
    });
  };

  const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
  };

  // 기본 URL 설정
  const requests = axios.create({
    baseURL: baseURL, // 원하는 API 주소로 변경하세요.
    withCredentials: true
  });

  // 환경변수에 따라 요청값을 변경해야할때 사용
  requests.interceptors.request.use((config) => {
    // 요청 전에 필요한 설정을 여기에 추가하세요.
    return config;
  });

  // 환경변수에 따라 응답값을 변경해야할때 사용
  requests.interceptors.response.use(
    (response) => {
      // 응답을 처리하는 데 필요한 로직을 여기에 추가하세요.
      const { data, _data, message, _message, statusCode, _statusCode } =
        response.data;

      const extractedData = {
        data: data || _data,
        message: message || _message,
        statusCode: statusCode || _statusCode,
      };

      return extractedData;
    },

    async (error) => {
      const { config, response } = error;
      const message = response.data.error.message;
      const originalReq = config;

      if (message === "Not authentication") {
        TokenService.clear();
        alert(
          "간편 로그인 토큰이 만료. 로그아웃 이후 처음 페이지로 돌아갑니다."
        );
        window.location.href = "/";
        return;
      } else if (message === "Token expired") {
        if (!isTokenRefreshing) {
          // const { token, setToken } = useTokenStore();
          isTokenRefreshing = true;
          const { data } = await reissuanceToken(pageType);
          const { accessToken } = data;
          TokenService.save(accessToken);
          // setToken({ accessToken, refreshToken: token.refreshToken });

          isTokenRefreshing = false;

          originalReq.headers.Authorization = `Bearer ${accessToken}`;

          onTokenRefreshed(accessToken);
          refreshSubscribers = [];

          return axios(originalReq);
        }
        const retryOriginalRequest = new Promise((resolve) => {
          addRefreshSubscriber((accesstoken) => {
            originalReq.headers.Authorization = "Bearer " + accesstoken;
            resolve(axios(originalReq));
          });
        });
        return retryOriginalRequest;
      }
      return Promise.reject(error);
    }
  );
  return requests;
}

const requests = {
  olea: BindInterceptors(process.env.REACT_APP_OLEA_API_URL, "olea"),
  orfeo: BindInterceptors(process.env.REACT_APP_ORFEO_API_URL, "orfeo"),
  calliope: BindInterceptors(
    process.env.REACT_APP_CALLIOPE_API_URL,
    "calliope"
  ),
};
export default requests;
