import reqeusts from "../../request";

//고객센터 > 공지 제거
export const deleteAnnouncements = async (id) => {
  const data = await reqeusts.orfeo.delete(`announcements/${id}`);
  return data;
};

//고객센터 > 이벤트 제거
export const deleteEvents = async (id) => {
  const data = await reqeusts.orfeo.delete(`events/${id}`);
  return data;
};

//고객센터 > faq 제거
export const deleteFaq = async (id) => {
  const data = await reqeusts.orfeo.delete(`faq/${id}`);
  return data;
};

//공연관리 > 공연 > 공연 제거
export const deleteProducts = async (id) => {
  const data = await reqeusts.orfeo.delete(`products/${id}`);
  return data;
};
