import { useMemo, useEffect } from "react";
import { useExportStore, useOptionsStore } from "../../../../../store/creates";
import MuiTable from "../../../../common/MuiTable";
import {} from "../../../../../query/queries/calliope/customerService";
import {
  useDeleteFaq,
  useGetFaq,
} from "../../../../../query/queries/orfeo/customerService";

export default function GetFaq() {
  const endpoint = "faq";
  const uniKey = "faqId";

  const { data: resData, isSuccess } = useGetFaq();
  const { mutateAsync } = useDeleteFaq();
  const originData = useMemo(() => resData || [], [resData]);
  const { setOptions, clearOptions, options } = useOptionsStore();
  const { setExport } = useExportStore();

  const deleteFaq = (id) => {
    mutateAsync(id);
  };

  useEffect(() => {
    const OPTIONS = { ...options };
    OPTIONS[uniKey] = [
      {
        name: "EXPORT",
        action: (id, data) => {
          const EXPORT = {};
          EXPORT[endpoint] = { id, data };
          setExport(EXPORT);
        },
        tooltip: "공지 등록 및 수정 > 수정 탭에서 IMPORT로 내보낼 수 있습니다.",
      },
      {
        name: "삭제",
        action: (id) => {
          deleteFaq(id);
        },
        tooltip: "해당 데이터를 제거합니다.",
      },
    ];
    setOptions(OPTIONS);
    return () => {
      clearOptions();
    };
  }, []);

  return (
    <div id="getFaq">
      {isSuccess && originData.length > 0 && (
        <MuiTable
          rows={originData}
          uniKey={uniKey}
          headers={Object.keys(originData[0])}
          queryKey={endpoint} //api endpoint
        />
      )}
    </div>
  );
}
