import * as Yup from "yup";

export default class Schema {
  static signin() {
    return Yup.object().shape({
      number: Yup.string().required("필수 항목입니다."),
      password: Yup.string()
        .matches(
          /^.*(?=^.{8,20}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/,
          "비밀번호는 문자 숫자 특수문자의 조합으로 8~20자로 입력해주세요."
        )
        .required("비밀번호를 입력해주세요"),
    });
  }
  static seat() {
    return Yup.object().shape({
      x: Yup.number()
        .min(0, "0 이상 입력")
        .max(50, "50 이하 입력")
        .required("x 필드는 필수 항목입니다."),
      y: Yup.number()
        .min(0, "0 이상 입력")
        .max(50, "50 이하 입력")
        .required("y 필드는 필수 항목입니다."),
      name: Yup.string()
        .min(1, "1자 이상 작성하세요.")
        .max(20, "20자 이내 작성하세요.")
        .required("섹션명은 필수 입력입니다."),
    });
  }
  static notice(isId = false) {
    if (isId) {
      return Yup.object().shape({
        id: Yup.number().required("필수 항목입니다."),
        title: Yup.string()
          .max(50, "50자 이하 입력")
          .required("필수 항목입니다."),
        content: Yup.string()
          .max(5000, "5000자 이하 입력")
          .required("필수 항목입니다."),
      });
    } else {
      return Yup.object().shape({
        title: Yup.string()
          .max(50, "50자 이하 입력")
          .required("필수 항목입니다."),
        content: Yup.string()
          .max(5000, "5000자 이하 입력")
          .required("필수 항목입니다."),
      });
    }
  }

  static announcements(isId = false) {
    if (isId) {
      return Yup.object().shape({
        id: Yup.number().required("필수 항목입니다."),
        writor: Yup.string()
          .max(10, "10자 이하 입력")
          .required("필수 항목입니다."),
        title: Yup.string()
          .max(50, "50자 이하 입력")
          .required("필수 항목입니다."),
        content: Yup.string()
          .max(5000, "5000자 이하 입력")
          .required("필수 항목입니다."),
      });
    } else {
      return Yup.object().shape({
        writor: Yup.string()
          .max(10, "10자 이하 입력")
          .required("필수 항목입니다."),
        title: Yup.string()
          .max(50, "50자 이하 입력")
          .required("필수 항목입니다."),
        content: Yup.string()
          .max(5000, "5000자 이하 입력")
          .required("필수 항목입니다."),
      });
    }
  }
  static events(isId = false) {
    if (isId) {
      return Yup.object().shape({
        id: Yup.number().required("필수 항목입니다."),
        writor: Yup.string()
          .max(10, "10자 이하 입력")
          .required("필수 항목입니다."),
        title: Yup.string()
          .max(50, "50자 이하 입력")
          .required("필수 항목입니다."),
        content: Yup.string()
          .max(5000, "5000자 이하 입력")
          .required("필수 항목입니다."),
      });
    } else {
      return Yup.object().shape({
        writor: Yup.string()
          .max(10, "10자 이하 입력")
          .required("필수 항목입니다."),
        title: Yup.string()
          .max(50, "50자 이하 입력")
          .required("필수 항목입니다."),
        content: Yup.string()
          .max(5000, "5000자 이하 입력")
          .required("필수 항목입니다."),
      });
    }
  }
  static faq(isId = false) {
    if (isId) {
      return Yup.object().shape({
        id: Yup.number().required("필수 항목입니다."),
        category: Yup.string()
          .max(20, "20자 이하 입력")
          .required("필수 항목입니다."),
        writor: Yup.string()
          .max(10, "10자 이하 입력")
          .required("필수 항목입니다."),
        title: Yup.string()
          .max(50, "50자 이하 입력")
          .required("필수 항목입니다."),
        content: Yup.string()
          .max(5000, "5000자 이하 입력")
          .required("필수 항목입니다."),
      });
    } else {
      return Yup.object().shape({
        category: Yup.string()
          .max(20, "20자 이하 입력")
          .required("필수 항목입니다."),
        writor: Yup.string()
          .max(10, "10자 이하 입력")
          .required("필수 항목입니다."),
        title: Yup.string()
          .max(50, "50자 이하 입력")
          .required("필수 항목입니다."),
        content: Yup.string()
          .max(5000, "5000자 이하 입력")
          .required("필수 항목입니다."),
      });
    }
  }
}
