import React from "react";

function NotFound(props) {
  return (
    <div className="normal_con">
      <h1>페이지를 찾을 수 없습니다. 주소를 확인하세요.</h1>
    </div>
  );
}

export default NotFound;
