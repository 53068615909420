import * as React from "react";
import { styled, alpha } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from "./Modal";
import { useState } from "react";
import { useEffect } from "react";
import CodeSample from "./CodeSample";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function Options({ bindKey }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isOpen, setIsOpen] = useState(0);
  const [LS_DATA, SET_LS_DATA] = useState();

  const [sample, setSample] = useState({
    url: "",
    payload: "",
    description: "",
  });

  useEffect(() => {
    SET_LS_DATA(JSON.parse(localStorage.getItem(bindKey)));
  }, [isOpen]);

  useEffect(() => {
    if (!LS_DATA) return;
    setSample({
      url: LS_DATA.url || "",
      payload: LS_DATA.payload || "",
      description: LS_DATA.description || "",
    });
  }, [LS_DATA]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //샘플 추가
  const modalDatas = [
    {
      header: "샘플 편집",
      leftBtn: true,
      sort: "center",
      content: (
        <div className="list-form">
          <ul>
            {[
              {
                label: "URL",
                setKey: "url",
                placeholder: "URL",
                value: sample.url,
              },
              {
                label: "Payload",
                setKey: "payload",
                placeholder: "[key: value, key: value]",
                value: sample.payload,
              },
              {
                label: "설명",
                setKey: "description",
                placeholder: "설명",
                value: sample.description,
              },
            ].map((data) => (
              <li key={data.label}>
                <label htmlFor="">{data.label}</label>
                <input
                  value={data.value || ""}
                  type="text"
                  placeholder={data.placeholder}
                  onChange={(e) => {
                    const obj = { ...sample };
                    obj[data.setKey] = e.target.value;
                    setSample(obj);
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      ),
      ldcBtnHandler: () => {
        setIsOpen(0);
      },
      rightBtnHandler: () => {
        localStorage.setItem(bindKey, JSON.stringify(sample));
        setIsOpen(0);
      },
    },
  ];

  return (
    <div>
      <CodeSample sample={LS_DATA} />
      {isOpen !== 0 && <Modal data={modalDatas[isOpen - 1]} />}
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Options
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setIsOpen(1);
            handleClose();
          }}
          disableRipple
        >
          <EditIcon />
          샘플 편집
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            // dispatch(success({ sampleKey: bindKey }));
            handleClose();
          }}
          disableRipple
        >
          <VisibilityIcon />
          샘플 확인
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
