import { Button, Paper, TextField } from "@mui/material";
import TinyEditor from "../../../../../utils/TinyEditor";
import {
  useExportStore,
  useLeavingGuardStore,
  useModalStore,
} from "../../../../../store/creates";
import { useRef, useState, useEffect, useCallback } from "react";
import { Field, Form, Formik } from "formik";
import Schema from "../../../../../utils/validate/Schema";
import { useUpdateFaq } from "../../../../../query/queries/orfeo/customerService";
import MenuItem from "@mui/material/MenuItem";

const updateFields = [
  {
    name: "id",
    type: "number",
    placeholder: "제목을 입력해주세요.",
    sx: { mb: 1, display: "block" },
    disabled: true,
  },
  {
    name: "title",
    type: "text",
    placeholder: "faqId",
    sx: { mb: 1 },
  },
];

export default function UpdateFaq() {
  const endpoint = "faq";
  const { openModal } = useModalStore();
  const { setIsGuard } = useLeavingGuardStore();
  const { mutateAsync } = useUpdateFaq();
  const { exportData } = useExportStore();
  const editorRef = useRef(null);
  const importData = exportData[endpoint];
  const [isImport, setIsImport] = useState(false);

  useEffect(() => {
    //export 데이터 초기화
    const importData = exportData[endpoint];
    if (!importData) return;
  }, [exportData]);

  const getExportData = () => {
    if (!exportData[endpoint]) {
      openModal(-1); //export 없는 경우
      return;
    }
    setIsImport(!isImport);
  };

  const updateFaq = useCallback((values) => {
    if (editorRef.current) {
      const payload = { ...values };
      openModal(`FAQ를 수정 하시겠습니까?`, () => mutateAsync(payload));
    }
  }, []);

  useEffect(() => {
    if (isImport) {
      setIsGuard(true);
    }
  }, [isImport]);

  return (
    <div id="updateFaq">
      <Button variant="contained" color="primary" onClick={getExportData}>
        {!isImport ? "IMPORT" : "CLEAR"}
      </Button>
      {importData && (
        <Formik
          enableReinitialize={true}
          initialValues={{
            id: importData.id,
            title: importData.data.title,
            content: importData.data.content,
            writor: importData.data.writor,
            category: importData.data.category,
          }}
          validationSchema={() => Schema.faq(true)}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            updateFaq(values);
          }}
        >
          {({ errors, touched }) => (
            <Form>
              {isImport && (
                <>
                  <Paper sx={{ p: 1, mt: 1 }}>
                    {updateFields.map((item) => (
                      <div className="rel" key={item.name}>
                        <Field
                          name={item.name}
                          type={item.type}
                          className="formik-input"
                          id={
                            errors[item.name] &&
                            touched[item.name] &&
                            "error-input-border"
                          }
                        >
                          {({ field, form, meta }) => (
                            <>
                              <TextField
                                {...field}
                                sx={item.sx}
                                label={item.name}
                                placeholder={item.placeholder}
                                disabled={item.disabled}
                                error={meta.error && meta.touched}
                                helperText={
                                  meta.error && meta.touched && meta.error
                                }
                              />
                            </>
                          )}
                        </Field>
                      </div>
                    ))}
                    <div className="rel">
                      <Field
                        name="writor"
                        type="text"
                        className="formik-input"
                        id={
                          errors.writor &&
                          touched.writor &&
                          "error-input-border"
                        }
                      >
                        {({ field, form, meta }) => (
                          <>
                            <TextField
                              {...field}
                              sx={{ mb: 1 }}
                              label={"WRITOR"}
                              placeholder="작성자를 입력해 주세요."
                              error={meta.error && meta.touched}
                              helperText={
                                meta.error && meta.touched && meta.error
                              }
                            />
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="rel">
                      <Field
                        name="category"
                        select
                        className="formik-input"
                        id={
                          errors.category &&
                          touched.category &&
                          "error-input-border"
                        }
                      >
                        {({ field, form, meta }) => (
                          <TextField
                            {...field}
                            select
                            sx={{ minWidth: 150, mb: 1 }}
                            label={"CATEGORY"}
                            placeholder="카테고리를 입력해 주세요."
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched && meta.error
                            }
                          >
                            {["nft", "ticketing", "event"].map((data) => (
                              <MenuItem key={data} value={data}>
                                {data}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      </Field>
                    </div>
                    <TinyEditor
                      editorRef={editorRef}
                      initValue={importData.data.content}
                      name="content"
                    />
                  </Paper>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 1 }}
                    type="submit"
                  >
                    FAQ 수정
                  </Button>
                </>
              )}
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
