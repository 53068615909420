import { getClubNftUser } from "../../apis/olea/GET";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import TokenService from "../../../service/token";
import { useModalStore, useTokenStore } from "../../../store/creates";
import requests from "../../request";
import { onErrorBind } from "../common";
import { useNavigate } from "react-router-dom";
import { signIn } from "../../apis/olea/POST";

export function useSignIn() {
  const queryClient = useQueryClient();
  const { setToken } = useTokenStore();
  const { openModal } = useModalStore();
  const navigate = useNavigate();

  return useMutation((payload) => signIn(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["post", "signIn"]);
      const { accessToken, refreshToken } = res.data;
      TokenService.save(accessToken, refreshToken);
      //로그인시 쿠키에 저장
      setToken({ accessToken, refreshToken }); //로그인시 스토어에 토큰 저장
      requests.olea.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`;
      navigate("/");
    },
    onError: (e) => {
      const message = e.response.data.error.message;
      openModal(message, false);
      onErrorBind(e);
    },
  });
}

export const useGetClubNftUser = (id = "") => {
  return useQuery({
    queryKey: ["get", `clubNftUser/${id}`],
    queryFn: async () => {
      const { data } = await getClubNftUser(id);
      return data;
    },
    onError: (e) => {
      console.log("e", e);
    },
  });
};
